@tailwind base;
@tailwind components;
@tailwind utilities;

.inset-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

body {
  overflow-x: hidden;
}

.circle-right {
  position: absolute;
  z-index: -5;
  border-radius: 50%;
  left: 50%;
  top: 1800px;
  width: 2100px;
  height: 2100px;
  background-color: #f2f8ff;
}

.circle-left {
  position: absolute;
  z-index: -5;
  border-radius: 50%;
  top: -670px;
  right: 50%;
  width: 2100px;
  height: 2100px;
  background-color: #f2f8ff;
}
